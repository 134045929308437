import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertModule } from 'ngx-bootstrap/alert';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NewPlaygroundRoutingModule } from './new-playground-routing.module';
import { Injectable } from '@angular/core';
import { QueryService } from '../../services/query.service';
import { ChatService } from '../../services/chat.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ToastrModule } from 'ngx-toastr';
import { NewPlaygroundComponent } from './new-playground.component';
import { SimulationModule } from '../../simulation/simulation.component.module';
import { PromptBaseComponent } from '../playground/components/prompt/prompt.base.component';
import { SharedModule } from '../../shared/shared.module';



@NgModule({
  declarations: [
    NewPlaygroundComponent
  ],
  providers: [ QueryService, ChatService],
  imports: [
    CommonModule,
    ToastrModule,
    RouterModule,
    FormsModule,
    SimulationModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    NewPlaygroundRoutingModule,
    SharedModule
  ],
  exports: [
    NewPlaygroundComponent
  ]
})
export class NewPlaygroundModule { }
