import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { UserService } from '../../services/user.service';
import { QueryService } from '../../services/query.service';
import { FormBuilder } from '@angular/forms';
import { IChatHistory, IQuery } from '../../shared/interfaces/query.interface';
import { faCopy, faHashtag, faTag } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { PromptService } from '../../services/prompt.service';
import { IPrompt } from '../../shared/interfaces/prompt.interface';
import { CookieService } from 'ngx-cookie-service';
const sessionDays = 30;
const sessionExpires = new Date(Date.now() + sessionDays * 24 * 60 * 60 * 1000);
@Component({
    selector: 'app-new-playground',
    templateUrl: './new-playground.component.html',
    styleUrls: ['./new-playground.component.scss']
})
export class NewPlaygroundComponent implements OnInit, OnDestroy, AfterViewInit {
    data: IQuery | null = null;
    faTag = faTag;
    faHashtag = faHashtag;
    faCopy = faCopy;
    token = this.route.snapshot.params['id'];
    query: Observable<any | null> = this.queryService.latestQuery$;
    public $message: BehaviorSubject<IChatHistory | null> = new BehaviorSubject<IChatHistory | null>(null);
    public message$: Observable<IChatHistory | null> = this.$message.asObservable();
    public activePrompt: Observable<IPrompt | null> = this.promptService.activePrompt$;
    active_prompt: string | null = null;
    stage: number | null = 1;
    constructor(
        private route: ActivatedRoute,
        private userService: UserService,
        private queryService: QueryService,
        private promptService: PromptService,
        private cookieService: CookieService,
        public fb: FormBuilder,
        private toastr: ToastrService
    ) {
        // this.userService.verify().subscribe(
        //     response => {
        //     },
        //     error => {
        //         // this.router.navigate(['/login']);
        //     }
        // )
    }

    ngOnInit(): void {
        this.query.subscribe((data: IQuery) => {
            // console.log(data)
            if (data && data.stage && data.stage >= 3) {
                this.stage = data.stage;
                console.log(data)
                this.switchActivePrompt(this.stage, data);
            }
        });
    }
    ngAfterViewInit() {
        this.initMessage();
    }
    ngOnDestroy() {
        this.queryService.unLoadConversation();
    }
    initMessage() {
        console.log('CHECK COOKIE SESSION', this.cookieService.check('session'))
        this.cookieService.check('session') ? this.initiateCookieMessage() : this.cookieAccept();
    }
    cookieAccept() {
        this.userService.cookieAccept().subscribe(
            cookieToken => {
                this.cookieService.set('session', cookieToken.token, { expires: sessionExpires, sameSite: 'Lax' });
                this.queryService.fetchMessage(true, this.token).subscribe(response => {
                    this.initiateCookieMessage()
                }, error => {
                    //this.initiateCookieMessage();
                });
            },
            error => {
                // console.log(error);
                // this.initiateCookieMessage();
            }
        );
    }
    initiateCookieMessage(): void {
        this.queryService.checkStatus(true, this.token);
        this.queryService.latestQuery$.subscribe(query => {
            if (query !== null && query.chat_history.length < 1) {
                setTimeout(() => {
                    this.queryService.appendMessageLocal({
                        content: `This is Jose Mourinho. I've heard your friend scored an own goal`,
                        type: 'video',
                        "mux_id": "Y013S2UpXn01V400ZE3gEfEl4djDp8uuaC8EbLRCOTWmww",
                        "mux_playback_ids": [
                            {
                                "policy": "public",
                                "id": "wk42NPHOQG01023PEFB6bSW1wFy02gNgUPmtGNqE02DLvJo",
                            }
                        ],
                        role: "agent"
                    }, 4000).subscribe(result => {
                        console.log('POSTED MESSAGE', result)
                    });
                }, 2000);
            }
        });
    }
    saveQuery(event: { _id: string, query: string, change: string; }): void {
        this.promptService.updateHistory(event._id, event.query, event.change).subscribe(response => {
            this.toastr.success('Saved', 'updated successfully');
        }, error => {
            this.toastr.error('Error', 'unable to save');
        });
    }
    safteyData(data: IChatHistory): void {
        console.log({ EVENT: data });
        this.$message.next(data);
    }
    loadHistory(): void {
    }
    addPrompt(): void {
    }
    switchActivePrompt(stage: number | null, data: IQuery): void {
        switch (this.stage) {
            case 3:
                this.promptService.updateActivePromptByID(data.name_prompt);
                break;
            case 4:
                this.promptService.updateActivePromptByID(data.friend_prompt);
                break;
            case 5:
                this.promptService.updateActivePromptByID(data.goal_prompt);
                break;
            default:
                this.promptService.updateActivePromptByID(data.goal_prompt);
                break;
        }
    }
    public resetQuery(isTest?: boolean): void {
        this.cookieService.delete('session');
        this.stage = 1;
        this.queryService.resetQuery(isTest || true);
    }
    onChange(deviceValue: Event) {
        if ((deviceValue.target as HTMLInputElement).value) {
            this.stage = Number((deviceValue.target as HTMLInputElement).value);
            this.queryService.updateStage(this.data?._id ?? '', this.stage);
            this.switchActivePrompt(this.stage, this.data as IQuery);
        }
    }
    public copyToClipboard(text: string): void {
        // this.toastService.newToast({message:'Copied to clipboard', type:'success'});
        this.toastr.success('Copied', 'Copying to clipboard');
        if (navigator.clipboard) {
            navigator.clipboard.writeText(text);
        } else {
            // Clipboard API not supported, fallback to alternative method
            // Implement your own logic here
        }
    }




}