<div class="playground">
    <div class="app-container">
        <div class="home-header">
            <div class="header">
                <h2>Application Simulation</h2>
            </div>
            <div class="sub-header" *ngIf="(query | async); let query">
                <span class="token" (click)="copyToClipboard(query.token)">
                    <p>{{query.token}}</p>
                    <fa-icon [icon]="faCopy"></fa-icon>
                </span>
            </div>
        </div>
        <app-simulation-component (saftey)="safteyData($event)" [devMode]="true" [testing]="true"></app-simulation-component>
        <div class="overlay"></div>
    </div>
    <div class="context-container" *ngIf="(query | async); let query">
        <div class="nav" *ngIf="(activePrompt | async); let activePrompt">
            <div class="header">
                <h2>Current Agent: {{activePrompt.title}}</h2>
            </div>
            <div class="sub-header"><span class="token" (click)="copyToClipboard(query.token)">
                    <p>{{query.token}}</p>
                    <fa-icon [icon]="faCopy"></fa-icon>
                </span></div>
        </div>
        <div class="content-container">
            <div class="menu" *ngIf="(activePrompt | async); let activePrompt">
                <div class="prompts pre-process" *ngIf="activePrompt.prechain_prompt_includes.length>0">
                    <ng-container *ngFor="let system of activePrompt.prechain_prompt_includes">
                        <app-comp-prompt [promptId]="system._id" (save)="saveQuery($event)"></app-comp-prompt>
                    </ng-container>
                </div>
                <div class="prompts second-process" *ngIf="activePrompt.second_chain_prompt_includes.length>0">
                    <ng-container *ngFor="let system of activePrompt.second_chain_prompt_includes">
                        <app-comp-prompt [promptId]="system._id" (save)="saveQuery($event)"></app-comp-prompt>
                    </ng-container>
                </div>

                <div class="prompts fourth-process" *ngIf="activePrompt.system_prompt_includes.length>0">
                    <ng-container *ngFor="let system of activePrompt.system_prompt_includes">
                        <app-comp-prompt [promptId]="system._id" (save)="saveQuery($event)"></app-comp-prompt>
                    </ng-container>
                </div>
                <div class="prompts fifth-process" *ngIf="activePrompt && activePrompt.prompt_includes.length>0">
                    <ng-container *ngFor="let system of activePrompt.prompt_includes">
                        <app-comp-prompt [promptId]="system._id" (save)="saveQuery($event)"></app-comp-prompt>
                    </ng-container>
                </div>
                <!-- <div class="prompts sixth-process"
                    *ngIf="active_prompt && active_prompt.fifth_chain_prompt_includes.length>0">
                    <ng-container *ngFor="let user of active_prompt.fifth_chain_prompt_includes">
                        <app-comp-prompt [promptId]="user._id" (save)="saveQuery($event)"></app-comp-prompt>
                    </ng-container>
                </div> -->
                <div class="prompts post-process"
                    *ngIf="activePrompt && activePrompt.postchain_prompt_includes.length>0">
                    <ng-container *ngFor="let system of activePrompt.postchain_prompt_includes">
                        <app-comp-prompt [promptId]="system._id" (save)="saveQuery($event)"></app-comp-prompt>
                    </ng-container>
                </div>
                <div class="prompts third-process"
                    *ngIf="activePrompt && activePrompt.third_chain_prompt_includes.length>0">
                    <ng-container *ngFor="let system of activePrompt.third_chain_prompt_includes">
                        <app-comp-prompt [promptId]="system._id" (save)="saveQuery($event)"></app-comp-prompt>
                    </ng-container>
                </div>
                <!-- <span><button class="btn" (click)="addPrompt()">Add Prompt</button></span> -->
            </div>
            <div class="menu">
                <div class="empty-panel">
                    <div class="action-group">
                        <div class="action-header">
                            <h2>Action Group</h2>
                        </div>
                        <div class="action-block">
                            <div class="row">
                                <div class="item">
                                    <label>New Chat </label>
                                    <button class="btn" (click)="resetQuery(true)">Reset</button>
                                </div>
                                <div class="item">
                                    <label>Start chat from </label>
                                    <select name="pets" id="pet-select" (change)="onChange($event)">
                                        <option value="">--Please choose an option--</option>
                                        <option value="3">Name Prompt</option>
                                        <option value="4">Friend Prompt</option>
                                        <option value="5">Own Goal</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="action-footer">
                        </div>
                    </div>
                    <div class="action-group">
                        <div class="action-header">
                            <h2>Output Message</h2>
                        </div>
                        <div class="action-block" *ngIf="query">
                            <div class="row" *ngIf="(message$ | async); let message">
                                {{message.content}}
                                <div class="results pre-process" *ngIf="message.role !=='agent'">
                                    <strong>Zefr Voilations: </strong>
                                    <p *ngFor="let v of message?.saftey?.zefr?.violations"><span>{{v}}</span></p>
                                    <span><strong>Zefr Warnings: </strong>{{message?.saftey?.zefr?.warnings}}</span>
                                    <span><strong>Compliance: </strong>{{message.saftey?.compliance}}</span>
                                    <span><strong>Evaluation: </strong>{{message.saftey?.evaluation}}</span>
                                </div>
                                <div class="results second-process">
                                    <span><strong>Revised Own Goal: </strong>{{message.saftey?.revised_owngoal}}</span>
                                </div>

                                <div class="results fourth-process">
                                    <span><strong>Insult: </strong>{{message.saftey?.insult}}</span>
                                    <span><strong>Football Analogy: </strong>{{message.saftey?.football_analogy}}</span>
                                </div>
                                <div class="results fifth-process">
                                    <span><strong>Final Response: </strong>{{message.saftey?.final_response}}</span>
                                    <span><strong>Final Response Revision:
                                        </strong>{{message.saftey?.final_revision}}</span>
                                </div>
                                <div class="results sixth-process">
                                    <span><strong>Final Compliance:
                                        </strong>{{message.saftey?.response_compliance}}</span>
                                    <span><strong>Final Revision Compliance:
                                        </strong>{{message.saftey?.response_revision_compliance}}</span>
                                </div>
                                <div class="results post-process" *ngIf="message.role ==='agent'">
                                    <span><strong>Final Compliance: </strong>{{message.saftey?.compliance}}</span>
                                    <span><strong>Response Revision Compliance:
                                        </strong>{{message.saftey?.response_revision_compliance}}</span>
                                    <span><strong>Response Compliance:
                                        </strong>{{message.saftey?.response_compliance}}</span>
                                    <span><strong>Response Evaluation
                                        </strong>{{message.saftey?.response_evaluation}}</span>
                                </div>
                                <div class="results third-process">

                                    <span><strong>Script:
                                        </strong>{{message.saftey?.script}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="action-footer">
                        </div>
                    </div>
                    <div class="action-group">
                        <div class="action-header">
                            <h2>Latest Media Outputs</h2>
                        </div>
                        <div class="action-block" *ngIf="query">
                            <div class="row" *ngIf="(message$ | async); let message">
                                <!-- <div class="media" *ngIf="(video$ | async); let video">
                                    <div class="media-wrapper">
                                        <video [id]="message._id" class="video" preload="auto" width="100%" *ngIf="message.mux_playback_ids.length>0">
                                            <source src="/api/v1/video/stream?key={{message.link}}" type="video/mp4" />
                                        </video>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                        <div class="action-footer">
                        </div>
                    </div>
                </div>
                <!-- <span><button class="btn" (click)="addPrompt()">Add Prompt</button></span> -->
            </div>
            <div class="menucontent">
            </div>
        </div>
        <div class="footer">
        </div>
    </div>
</div>